import { useState, useEffect } from "react";
import Marquee from "react-text-marquee";
import KeyCloackService from "../../services/KeyCloackService";
import UserService from "../../services/UserService";

const getInitials = (user) => {
  let initials = "";
  if (user && user.first_name && user.last_name) {
    initials = `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`;
  } else if (user.username) {
    initials = `${user.username.charAt(0)}`;
  }
  return initials;
};

const removeEmailString = (username) => {
  
  if(username?.lastIndexOf("@") !== -1) {
    username = username?.substring(0, username?.lastIndexOf("@"))
  }
  return username
}

const UserProfile = (props) => {
  const { userId, hideName, user, showTeamLabel, reverse, placeholder } = props;
  const [userProfile, setUserProfile] = useState();
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    if (KeyCloackService.isLoggedIn() && userId && userId?.length >= 24) {
    setLoading(true)
      UserService.getUser(userId).then((Ruser) => {
        setUserProfile(Ruser);
        setLoading(false);
      }).catch(error => {
        setLoading(false);
      })
    }

    if (user) {
      setUserProfile(user);
      setLoading(false);
    }

    return(() => {
      setUserProfile();
      setLoading();
    })
  }, [userId, user]);

  let initials;
  if (userProfile) {
    initials = getInitials(userProfile);
  }

  const userName = removeEmailString(userProfile?.username)

  return (
    <div className={`user-profile ${reverse ? "row-reverse" : ""}`}>
      <figure className="avatar">
        <span className="text-uppercase">
          {!initials ? <i className="las la-user"></i> : initials}
        </span>
        {userProfile?.profile_picture && (
          <img alt="profile" src={userProfile?.profile_picture} />
        )}
      </figure>

      {!loading && !hideName && (
        <span 
          className={`skp-link-hover text username text-truncate p-2 ${!userProfile?.username && placeholder ? "placeholder-style" : ""}`} 
          onClick={!userProfile?.username && placeholder ? undefined : props.onUserNameClick}
        >
          <Marquee 
            text={`${userProfile?.username ? userName : placeholder ? placeholder : ""}`} 
          />
        </span>
      )}

      {(!loading || !user || !userId) && <>{props.children}</>}{" "} 

      {loading && (userId || user) && KeyCloackService.isLoggedIn() && <span className="skp-text-primary text p-2">Loading user...</span>}
      
      
    </div>
  );
};

export default UserProfile;
