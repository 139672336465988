import React, { useEffect, useState, useContext } from "react";
import { SeedTeam, SeedItem } from "react-brackets";
import KeyCloackService from "../../../services/KeyCloackService";
import { TournamentMatchesContext } from "../../Context/TournamentMatchesContext";
import MatchTeamName from "./MatchTeamName";
import IMatchTournamentSeed from "../../Interfaces/MatchTournamentSeed";
import IMatch from "../../Interfaces/Match";
import UserProfileWithDetailsModal from "../User/UserProfileWithDetailsModal";
import MatchResultService from "../../../services/MatchResultService";
import { EventsContext } from "../../Context/EventsContext";
import MatchService from "../../../services/MatchService";

type Props = {
  matchId: string;
  seed: IMatchTournamentSeed;
  visible: boolean;
  match: IMatch | undefined;
};

function SingleMatch(props: Props) {
  const { matchId, seed, visible, match } = props;
  const { tournamentMatches } = useContext(TournamentMatchesContext);
  const [winner, setWinner] = useState<string>();
  const [multipleMatchResult, setMultipleMatchResult] = useState<{
    winner: string | undefined;
    teamHomeScores: number[];
    teamAwayScores: number[];
  }>();

  const multipleMatch = seed?.matchesId?.length > 1;
  const [homeScore, setHomeScore] = useState(match?.result?.scores[0]?.score);
  const [awayScore, setAwayScore] = useState(match?.result?.scores[1]?.score);
  const [mainPlayerHome, setMainPlayerHome] = useState(
    match?.result?.scores[0].playerResults?.[0]?.playerId || null
  );
  const [mainPlayerAway, setMainPlayerAway] = useState(
    match?.result?.scores[1].playerResults?.[0]?.playerId || null
  );
  const [playerHomeWinner, setPlayerHomeWinner] = useState<boolean>(false);
  const [playerAwayWinner, setPlayerAwayWinner] = useState<boolean>(false);
  const { socket, isConnected } = useContext(EventsContext);
  const [fromWinnerMatch, setFromWinnerMatch] = useState<Array<IMatch>>([]);
  const [fromLoserMatch, setFromLoserMatch] = useState<Array<IMatch>>([]);

  // Hook per la gestione del socket
  useEffect(() => {
    if (isConnected && socket && matchId) {
      socket.onAny((event, ...args) => {
        if (
          event === matchId &&
          args.toString() !== "update" &&
          args.toString() !== "refresh"
        ) {
          const matchElement = args[0];
          setWinner(matchElement.winner);
          setHomeScore(matchElement.homeScore);
          setAwayScore(matchElement.awayScore);
          setPlayerHomeWinner(matchElement.homeScore > matchElement.awayScore);
          setPlayerAwayWinner(matchElement.awayScore > matchElement.homeScore);
        }
      });

      return () => {
        socket.offAny();
      };
    }
  }, [matchId, isConnected, socket]);

  useEffect(() => {
    if (multipleMatch && match) {
      setMultipleMatchResult(
        MatchResultService.getMultipleMatchWinnerNoSeed([match])
      );
    } else if (match) {
      if (
        homeScore === undefined ||
        homeScore === match?.result?.scores[0]?.score
      ) {
        setHomeScore(match?.result?.scores[0]?.score);
      }
      if (
        awayScore === undefined ||
        awayScore === match?.result?.scores[1]?.score
      ) {
        setAwayScore(match?.result?.scores[1]?.score);
      }
      setMainPlayerHome(
        match?.result?.scores[0]?.playerResults?.[0]?.playerId || null
      );
      setMainPlayerAway(
        match?.result?.scores[1]?.playerResults?.[0]?.playerId || null
      );
      if (winner === undefined || winner === match?.winner) {
        setWinner(match.winner);
      }
      if (
        playerHomeWinner === undefined ||
        playerHomeWinner ===
          match?.result?.scores[0]?.score > match?.result?.scores[1]?.score
      ) {
        setPlayerHomeWinner(
          match?.result?.scores[0]?.score > match?.result?.scores[1]?.score
        );
      }
      if (
        playerAwayWinner === undefined ||
        playerAwayWinner ===
          match?.result?.scores[1]?.score > match?.result?.scores[0]?.score
      ) {
        setPlayerAwayWinner(
          match?.result?.scores[1]?.score > match?.result?.scores[0]?.score
        );
      }
      setFromWinnerMatch(
        getSourceWinnerMatches(tournamentMatches?.tournament, seed.id)
      );
      setFromLoserMatch(
        getSourceLoserMatches(tournamentMatches?.tournament, seed.id)
      );
    }
  }, [
    match,
    multipleMatch,
    homeScore,
    awayScore,
    mainPlayerHome,
    mainPlayerAway,
    winner,
    playerHomeWinner,
    playerAwayWinner,
  ]);

  function getSourceWinnerMatches(tournament, matchId) {
    return tournament.matches.filter((match) => match.nextMatch === matchId);
  }

  function getSourceLoserMatches(tournament, matchId) {
    return tournament.matches.filter((match) => match.loserMatch === matchId);
  }

  function getMatchById(fromMatch) {
    if (!fromMatch?.matchesId?.[0]) return null; // Se non c'è un matchId, ritorna null

    const matchId = fromMatch.matchesId[0];

    return (
      tournamentMatches?.matches?.find((match) => match.id === matchId) || null
    );
  }

  function getPlaceHolder(index) {
    if (fromWinnerMatch?.length > 0 && fromLoserMatch?.length > 0) {
      if (getMatchById(fromLoserMatch[0])?.status !== 3)
        return index === 0
          ? `Winner match ${fromWinnerMatch[0]?.id ?? ""}`
          : `Loser match ${fromLoserMatch[0]?.id ?? ""}`;
      else
        return index === 0
          ? `Loser match ${fromLoserMatch[0]?.id ?? ""}`
          : `Winner match ${fromWinnerMatch[0]?.id ?? ""}`;
    }

    const winner = fromWinnerMatch?.[index]?.id ?? "";
    const loser = fromLoserMatch?.[index]?.id ?? "";

    if (winner) return `Winner match ${winner}`;
    if (loser) return `Loser match ${loser}`;

    return "";
  }

  return (
    <SeedItem className="skp-brackets-seed-item" prova="pippo">
      {match ? (
        <div className="skp-brackets-seed-item-team">
          <SeedTeam className="skp-brackets-seed-item-team-home">
            {KeyCloackService.isLoggedIn() && (
              <>
                {tournamentMatches?.tournament?.playerType === 0 && (
                  <>
                    <UserProfileWithDetailsModal
                      userId={mainPlayerHome}
                      tournament={tournamentMatches?.tournament}
                      placeholder={getPlaceHolder(0)}
                    />
                    {mainPlayerHome === winner && (
                      <span className="skp-text-xxsmall skp-text-ternary">
                        <img
                          alt="logo-winner"
                          style={{ width: 24, marginRight: 4, marginLeft: -4 }}
                          src="https://img.icons8.com/external-flaticons-flat-flat-icons/344/external-winner-achievements-flaticons-flat-flat-icons-2.png"
                        />
                      </span>
                    )}
                  </>
                )}
                {tournamentMatches?.tournament?.playerType === 1 && (
                  <MatchTeamName
                    teamId={match?.result?.scores[0].teamId}
                    tournament={tournamentMatches?.tournament}
                    hideTeamLabel={true}
                  />
                )}
              </>
            )}
            {!KeyCloackService.isLoggedIn() && (
              <div className="user-profile">
                <figure className="avatar">
                  <span className="text-uppercase">
                    <i className="las la-user"></i>
                  </span>
                </figure>
              </div>
            )}
            {!multipleMatch ? (
              <div className={playerHomeWinner ? "skp-text-quaternary" : ""}>
                {homeScore}
              </div>
            ) : (
              <div>
                <span className="player-score">
                  {multipleMatchResult?.teamHomeScores.length}
                </span>
              </div>
            )}
          </SeedTeam>

          <SeedTeam className="skp-brackets-seed-item-team-away">
            {KeyCloackService.isLoggedIn() && (
              <>
                {tournamentMatches?.tournament?.playerType === 0 && (
                  <>
                    <UserProfileWithDetailsModal
                      userId={mainPlayerAway}
                      tournament={tournamentMatches?.tournament}
                      placeholder={getPlaceHolder(1)}
                    />
                    {mainPlayerAway === winner && (
                      <span className="skp-text-xxsmall skp-text-ternary">
                        <img
                          alt="logo-winner"
                          style={{ width: 24, marginRight: 4, marginLeft: -4 }}
                          src="https://img.icons8.com/external-flaticons-flat-flat-icons/344/external-winner-achievements-flaticons-flat-flat-icons-2.png"
                        />
                      </span>
                    )}
                  </>
                )}
                {tournamentMatches?.tournament?.playerType === 1 && (
                  <MatchTeamName
                    teamId={match?.result?.scores[1].teamId}
                    tournament={tournamentMatches?.tournament}
                    hideTeamLabel={true}
                  />
                )}
              </>
            )}
            {!KeyCloackService.isLoggedIn() && (
              <div className="user-profile">
                <figure className="avatar">
                  <span className="text-uppercase">
                    <i className="las la-user"></i>
                  </span>
                </figure>
              </div>
            )}
            {!multipleMatch ? (
              <div className={playerAwayWinner ? "skp-text-quaternary" : ""}>
                {awayScore}
              </div>
            ) : (
              <div>
                <span className="player-score">
                  {multipleMatchResult?.teamAwayScores.length}
                </span>
              </div>
            )}
          </SeedTeam>
        </div>
      ) : (
        <div className="skp-brackets-seed-item-team">
          <SeedTeam className="skp-brackets-seed-item-team-home">
            <UserProfileWithDetailsModal
              userId={undefined}
              tournament={tournamentMatches?.tournament}
            />
          </SeedTeam>
          <SeedTeam className="skp-brackets-seed-item-team-away">
            <UserProfileWithDetailsModal
              userId={undefined}
              tournament={tournamentMatches?.tournament}
            />
          </SeedTeam>
        </div>
      )}
    </SeedItem>
  );
}

export default SingleMatch;
